import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";

import actionType from "../../../../../../Redux/Actions/ActionTypes";
import urls from "../../../../../../Utils/URLs";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// ------------------------
import Stop_icon from "../../../../../../designUtils/img/pause.svg";
import Play_icon from "../../../../../../designUtils/img/play.svg";
import Alert_icon from "../../../../../../designUtils/img/Alert.svg";
import Info from "../../../../../../designUtils/img/info.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
  faCloudDownloadAlt,
  faDownload
} from "@fortawesome/free-solid-svg-icons";
import StarRating from "../../Components/Rating/StarRating";
import ProductsAlertTicketCreate from "../../../TroubleTickets/Components/ProductTickets/ProductsAlertTicketCreate";
import CustomModal from "../../../../../Components/Modals/CustomModal";
import GetCatalogueProductDetailsService from "../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/GetCatalogueProductDetailsService";
import CatalogueItemDetails from "../../../../Components/Widgets/Products/CatalogueItemDetails";
import Can, { filterByPermission } from "../../../../../Components/Can";
import LoadingButton from "../../../../../Components/Buttons/LoadingButton";
import AddStoreProductService from "../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/AddStoreProductService";
import StoreOverviewService from "../../../../../../Service/DashboardServices/AccountOverviewServices/StoreServices/StoreOverviewService";
import UpdateProductManagementService from "../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/UpdateProductManagementService";
import QuickLaunchCampaignService from "../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/QuickLaunchCampaignService";
import CampaignLaunchTutorialModal from "./Modals/CampaignLaunchTutorialModal";
import QuickLaunchCampaignModal from "./Modals/QuickLaunchCampaignModal";
import Vector_Arrow from "./../../../../../../designUtils/Logo/Vector_Arrow.svg"
import Arrow_DownWords from "./../../../../../../designUtils/Logo/Arrow_DownWords.svg"
import ProductList from "./SubComponents/ProductList";
import * as FileSaver from 'file-saver';
import DownloadManagedAsinsServices from "../../../../../../Service/DashboardServices/UserProfileServices/DownloadManagedAsinsServices";
import * as XLSX from 'xlsx';
import notify from '../../../../../../../src/Notifications/ToastifyActions';
import UpdateBulkProductManagementService from "../../../../../../Service/DashboardServices/AccountOverviewServices/ProductServices/UpdateBulkProductManagementService";
function StoreOverviewNewProductPage(props) {
  let history = useHistory();
  const [isTicketListOpen, setIsTicketListOpen] = useState(false);
  const [asinForTicketList, setAsinForTicketList] = useState();
  const [catlogProductDetails, setCatlogProductDetails] = useState();
  const [isOpenCatalogueItem, setIsOpenCatalogueItem] = useState();
  const [isManagementOpen, setIsManagementOpen] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState('paused');
  const [isCampaignTutorialOpen, setIsCampaignTutorialOpen] = useState(false);
  const [hideCampaignTutorial, setHideCampaignTutorial] = useState(
    JSON.parse(localStorage.getItem('hide-campaign-tutorial')) || false
  );
  const [asinArray, setAsinArray] = useState([]);
  const [checked, setChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenChaild, setIsOpenChaild] = useState(true);
  const [isOpenOPChaild, setIsOpenOPChaild] = useState(true);

  const [filters, setFilters] = useState({
    search: "",
    management: "",
  });
  const [isCampaignOpen, setIsCampaignOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isAddProductOpen, setIsAddProductOpen] = useState(false);
  const [newProductASIN, setNewProductASIN] = useState("");
  const [childProductData, setChildProductData] = useState({
    asin: "",
    price: "",
    brand: "",
    budget: "",
    productBudget: '',
    link: "",
    sku: "",
    skus: [],
    IntegerId: "",
    simpliworksManagementState: ''

  });
  const [newCampaignDetails, setNewCampaignDetails] = useState({
    productASIN: '',
    productSKU: '',
    skuList: [],
    dailyBudget: 50,
    brand: '',
  });

  console.log(childProductData);

  const [filteredChildProducts, setFilteredChildProducts] = useState([]);
  const [filteredOrphanProducts, setFilteredOrphanProducts] = useState([]);
  const [maxLength, setMaxLength] = useState(0);

  const compareProducts = (product1, product2) => {
    if (product1.productTitle < product2.productTitle) return -1;
    else if (product1.productTitle > product2.productTitle) return 1;
    else return 0;
  };
  let groupedProducts = [...props.parentProducts, ...filteredOrphanProducts];
  let sortedParentOrphanProducts = groupedProducts.sort(compareProducts);

  useEffect(() => {
    setMaxLength(props.parentProducts.length + props.orphanProducts.length)
  }, [])
  useEffect(() => {
    if (props.childProducts) {
      setFilteredChildProducts(props.childProducts.sort(compareProducts));
    }
    if (props.orphanProducts) {
      setFilteredOrphanProducts(props.orphanProducts.sort(compareProducts));
    }
  }, [props.childProducts, props.orphanProducts])

  useEffect(() => {
    setFilteredChildProducts(filterProducts(props.childProducts));
    setFilteredOrphanProducts(filterProducts(props.orphanProducts));
  }, [filters]);

  const filterFunctions = {
    search: (product) =>
      product.productTitle.toLowerCase().includes(filters.search.toLowerCase()) ||
      product.asin.toLowerCase().includes(filters.search.toLowerCase()) ||
      product.parentProduct.toLowerCase().includes(filters.search.toLowerCase()) ||
      product.skus.join('').toLowerCase().includes(filters.search.toLowerCase()),
    management: (product) =>
      product.simpliworksManagementState === filters.management,
  };

  const filterProducts = (products) => {
    if (!products) return [];
    // console.log("surya")
    console.log(products)
    products = products.filter((product) =>
      Object.keys(filters).every((filterType) =>
        filters[filterType] ? filterFunctions[filterType](product) : true
      )
    );
    return products;
  };


  const updateProductManagement = async (asin, update) => {
    setLoadingUpdate(true);
    await UpdateProductManagementService(
      props.token,
      asin,
      update,
      props.storeIntegerID
    );
    props.dispatch(
      StoreOverviewService(
        props.token,
        props.storeIntegerID,
        props.storeAccountID
      )
    );
    setLoadingUpdate(false);
  };
  const updateBulkProductManagement = async (update) => {
    if (asinArray.length < 1) {
      return true
    }
    setLoadingUpdate(true);
    await UpdateBulkProductManagementService(
      props.token,
      asinArray,
      update,
      props.storeIntegerID
    );
    props.dispatch(
      StoreOverviewService(
        props.token,
        props.storeIntegerID,
        props.storeAccountID
      )
    );
    const updatedArray = [];
    setAsinArray(updatedArray);
    setLoadingUpdate(false);
  };
  function handleChange(e) {
    console.log(sortedParentOrphanProducts);
    console.log(props.parentProducts);
    console.log(props.orphanProducts);
    setChecked(e.target.checked);
    const value = e.target.value;

    if (e.target.checked === false) {
      if (value === 'All') {
        const updatedArray = [];
        setAsinArray(updatedArray);
        console.log(updatedArray);
      }
      else {
        const updatedArray = [...asinArray];
        const newArray = updatedArray.filter(item => item !== value);
        setAsinArray(newArray);
        console.log(newArray);
      }
    } else if (e.target.checked === true) {
      if (value === 'All') {
        const updatedArray = [];
        props.parentProducts.map((parent) => {
          updatedArray.push(parent.asin);
        })
        props.orphanProducts.map((parent) => {
          updatedArray.push(parent.asin);
        })
        setAsinArray(updatedArray);
        console.log(updatedArray);
      }
      else {
        const updatedArray = [...asinArray];
        updatedArray.push(value);
        setAsinArray(updatedArray);
        console.log(updatedArray);
      }

      // setFormData({ ...formData, ["product"]: updatedArray });
    }
  }
  const handleCreateAndViewTicketClose = (response) => {
    setIsTicketListOpen(false);
  };
  const managementStates = {
    INACTIVE: "unManaged",
    ACTIVE: "managed",
    PAUSED: "paused",
  };
  const handleAddProduct = async (asin) => {
    let response = await AddStoreProductService(
      props.token,
      asin,
      props.storeIntegerID,
      props.storeAccountID
    );
    if (response) {
      props.dispatch(
        StoreOverviewService(
          props.token,
          props.storeIntegerID,
          props.storeAccountID
        )
      );
      // if(!loading)
      // {
      //   window.location.reload(false);
      // }
    }
    return response;
  };

  const toggleFilter = (filterType, value) => {
    setIsOpenChaild(false)
    setIsOpen(false)
    if (filterType === "management" || filterType === "search") {
      setFilters((prevState) => ({
        ...prevState,
        [filterType]: prevState.management === value ? "" : value,
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        [filterType]: !prevState[filterType],
      }));
    }
  };
  useEffect(() => {
    if (managedProduct > 0) {
      toggleFilter("management", "managed")
    }
    else {
      toggleFilter("management", "unManaged")
    }
    localStorage.setItem('hide-campaign-tutorial', JSON.stringify(hideCampaignTutorial));
  }, [hideCampaignTutorial])

  const handleQuickLaunchCampaign = async (asin, sku, dailyBudget) => {
    setIsManagementOpen(true);
    let response = await QuickLaunchCampaignService(
      props.token,
      props.storeIntegerID,
      asin,
      sku,
      dailyBudget
    );
    if (response) {
      props.dispatch(
        StoreOverviewService(
          props.token,
          props.storeIntegerID,
          props.storeAccountID
        )
      );
    }
    return response;
  };
  const toggleCampaignModal = (asin, skuList) => {
    if (!hideCampaignTutorial) {
      setIsCampaignTutorialOpen(!isCampaignTutorialOpen);
    }
    setIsCampaignOpen(!isCampaignOpen);
    setNewCampaignDetails((prevState) => ({
      productSKU: skuList ? skuList[0] : '',
      productASIN: asin,
      skuList: skuList,
      dailyBudget: prevState.dailyBudget,
      productBudget: prevState.productBudget,
      brand: prevState.brand,
    }));
  };
  const renderAddProductModal = () => (
    <Can
      role={props.accessLevel}
      perform="products:add"
      yes={() => (
        <Col md="12" className="pl-0">
          {
             props.UserPermission?props.UserPermission.Products_AddProduct ? <Button

              className="btn-icon btn-icon-left btn-background-color"
              style={{ fontWeight: 600 }}
              onClick={() => setIsAddProductOpen(!isAddProductOpen)}
            >
              <i
                className="lnr-plus-circle btn-icon-wrapper"
                style={{ paddingBottom: "1px" }}
              >
                
              </i>
              Add Product
            </Button>:"":""
              
          }

          <CustomModal
            header="Add a product to this store"
            isOpen={isAddProductOpen}
            toggle={() => setIsAddProductOpen(!isAddProductOpen)}
          >
            <Row>
              <Col className="mb-3" md="12">
                <Label for="newProductASIN">Product ASIN</Label>
                <Input
                  id="newProductASIN"
                  placeholder="Enter product ASIN"
                  value={newProductASIN}
                  onChange={(e) => setNewProductASIN(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={{ offset: 4, size: 4 }}>
                <LoadingButton
                  color="primary"
                  onClick={async () => {
                    setLoading(true);
                    await handleAddProduct(newProductASIN);
                    setLoading(false);
                  }}
                  disabled={newProductASIN === ''}
                  loading={loading}
                >
                  Add Product
                </LoadingButton>
              </Col>
            </Row>
          </CustomModal>
        </Col>
      )}
    />
  );
  const renderProductList = () => (
    <Fragment>
      <Can
        role={props.accessLevel}
        perform={'home:simple'}
        yes={() => {
          return (
            <ProductList
              accessLevel={props.accessLevel}
              parentProducts={sortedParentOrphanProducts}
              childProducts={filteredChildProducts}
              updateProductManagement={updateProductManagement}
              quickLaunch={toggleCampaignModal}
              handleQuickActions={quickActions}
              toggleSelect={props.toggleSelect}
              selectedProducts={props.selectedProducts}
              card={true}
              storeIntegerID={props.storeIntegerID}
              token={props.token}
              isOpenChaild={isOpenChaild}
              setIsOpenChaild={setIsOpenChaild}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              handleChange={handleChange}
              asinArray={asinArray}
              setAsinArray={setAsinArray}
              setClass={(!filteredChildProducts.length && !filteredOrphanProducts.length)}
            />

          )

        }}
        no={() => (
          <></>
        )}
      />
      {
        (!filteredChildProducts.length && !filteredOrphanProducts.length) &&
        <h5>No products found.</h5>

      }
    </Fragment>
  );
  const quickActions = filterByPermission(
    [
      {
        perform: "project:create",
        yes: {
          id: "updateListing",
          name: params => 'Upgrade Product Listing',
          action: params => {
            props.dispatch({
              type: actionType.SET_CURRENT_STORE,
              payload: {
                id: props.storeIntegerID,
              }
            })
            history.push(`${urls.CREATE_PROJECTS_URL}/${props.storeIntegerID}`)
          },
          show: false,
        }
      },

      {
        perform: 'products:updateskus',
        yes: {
          id: "delete",
          name: () => "Update Skus",
          action: ({ productASIN, storeIntegerID }) => props.updateSkus(productASIN, storeIntegerID),
          show: true,
        }
      },
      {
        perform: 'home:standard',
        yes: {
          id: "viewDetails",
          name: (params) => 'View Product Details',
          action: (params) => props.handleRedirect(params.productASIN, params.productSKU),
          show: true,
        },
      },
    ],
    props.accessLevel
  );
  const totoalProduct = props.storeProducts.filter(item => item.isParentProduct === false).length;
  const unManagedProduct = props.storeProducts.filter(item => item.isParentProduct === false && item.simpliworksManagementState == 'unManaged').length;
  const managedProduct = props.storeProducts.filter(item => item.isParentProduct === false && item.simpliworksManagementState == 'managed').length;
  const pausedProduct = props.storeProducts.filter(item => item.isParentProduct === false && item.simpliworksManagementState == 'paused').length;

  const exportToCSVManagedProducts = (csvData, fileName) => {
    // console.log(csvData.length)
    if (!csvData) {
      notify.NotifySuccess('Asin management data is not available for this store.')
      return true
    }
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    console.log(csvData)
    console.log(fileName)
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'Managed Products': ws }, SheetNames: ['Managed Products'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    console.log('before save')
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  const downloadManagedAsins = async () => {
    let fileName = props.storeName ? props.storeName + '_Managed_Asin_List_' + new Date() : 'Managed_Asin_List_' + new Date()
    let data = {
      integerId: props.storeIntegerID
    }
    let response = await DownloadManagedAsinsServices(props.token, data);
    if (response) {
      console.log(response)
      exportToCSVManagedProducts(response.productsList, fileName)
    }
  }
  return (
    <>
        <Row className='ml-1'>
          <Col className="mb-3" md="12">
            <Row className="mb-3">
              {/* <Col md={9}></Col> */}
              <Col md={8}>{renderAddProductModal()}</Col>
              <Col md={4}>
                <Input
                  className="mb-2"
                  value={filters.search}
                  onChange={(e) => toggleFilter("search", e.target.value)}
                  placeholder="Search product by Title, ASIN or SKU "
                />
              </Col>
            </Row>
            <h6 className=" mb-1">
              Total Products ({totoalProduct})
            </h6>
            <hr></hr>
            {props.UserPermission?(props.UserPermission.Products_ManageUnmanage && <div className="mb-3 mt-2 d-flex align-items-center">

              <input
                className="mr-1"
                value={'All'}
                type="checkbox"
                name={'selectAllProducts'}
                onChange={handleChange}
                checked={asinArray.length < 1 ? false : maxLength != asinArray.length ? false : true}
              />
              <span> Select All</span>
              {asinArray.length > 0 ?
                <><img
                  src={Play_icon}
                  alt="Start"
                  title="Start Management"
                  className="ml-3"
                  width={30}
                  height={30}
                  onClick={() => updateBulkProductManagement('managed')}
                />
                  <img
                    src={Stop_icon}
                    alt="Pause"
                    className="ml-3"
                    title="Pause Management"
                    width={30}
                    height={30}
                    onClick={() => updateBulkProductManagement("paused")}
                  />
                </>
                : ''}
            </div>):''}


            <Row>
              <Col md="11" className='product-button-color1 d-flex'>
                <Button
                  outline
                  onClick={() =>
                    toggleFilter("management", managementStates.ACTIVE)
                  }
                  // active={filters.management === managementStates.ACTIVE}
                  color="light"
                  // className=" mr-2 btn-border product-button-color-inner "
                  className={filters.management === managementStates.ACTIVE ? " mr-2 btn-border product-button-color-inner" : 'mr-2 btn-border'}
                >
                  <span className="mt-1 text-brand-blue-dark">
                    <i
                      className="pe-7s-check"
                      style={{
                        fontSize: "1rem",
                        color: "green",
                        fontWeight: "bold",
                      }}
                    >

                    </i>
                    &nbsp; Managed by Simpliworks
                  </span>
                  <div className={"count-circle-1 active"}>
                    <div>
                      
                      <span className="count-circle-text">
                        {managedProduct}
                      </span>
                    </div>
                  </div>
                </Button>

                <Button
                  outline
                  onClick={() =>
                    toggleFilter("management", managementStates.PAUSED)
                  }
                  // active={filters.management === managementStates.PAUSED}
                  color="light"
                  // className="ml-2 mr-2 btn-border "
                  className={filters.management === managementStates.PAUSED ? 'ml-2 mr-2 btn-border product-button-color-inner' : 'ml-2 mr-2 btn-border '}
                >
                  <span className=" text-warning">
                    <i
                      className="pe-7s-attention text-warning"
                      style={{ fontSize: "1rem", fontWeight: "bold" }}
                    >
                      
                    </i>
                    Paused
                  </span>
                  <div className={"count-circle-2 active"}>
                    <div>
                      
                      <span className="count-circle-text">
                        {pausedProduct}
                      </span>
                    </div>
                  </div>
                </Button>
                <Button
                  outline
                  onClick={() =>
                    toggleFilter("management", managementStates.INACTIVE)
                  }
                  // active={filters.management === managementStates.INACTIVE}
                  color="light"
                  // className="ml-2 mr-2 btn-border"
                  className={filters.management === managementStates.INACTIVE ? 'ml-2 mr-2 btn-border product-button-color-inner' : 'ml-2 mr-2 btn-border'}
                >
                  {/* <i
                      className="pe-7s-close text-danger"
                      style={{ fontSize: "1rem", fontWeight: "bold" }}
                    ></i> */}
                  Unmanaged
                  <div className={"count-circle-3 active"}>
                    <div>
                      
                      <span className="count-circle-text">
                        {unManagedProduct}
                      </span>
                    </div>
                  </div>
                </Button>
              </Col>
              <Col md={1}>
                {props.accessLevel === 0  && props.UserPermission.Products_DownloadManagedAsins ? (
                  <button
                    title="Download Managed ASINs"
                    className="info-button-background-none"
                    onClick={() => downloadManagedAsins()}
                  >
                    <FontAwesomeIcon style={{ fontSize: '27px' }} icon={faDownload} />
                  </button>
                ) : (
                  ''
                )}</Col>
              {/*  {
                props.accessLevel == 0?
                <Col md='3'>
                <div className="d-flex justify-content-end mr-2">
                    <button
                    title="Download Managed ASINs"
                    className="info-button-background-none"
                    onClick={()=> downloadManagedAsins()}>
                    <FontAwesomeIcon  style={{fontSize:'30px'}}icon={faDownload}/>
                    </button>
                  </div>
                </Col>:'' 
              }*/}

            </Row>
          </Col>
          <Col className="col-md-12 d-flex">{renderProductList()}</Col>
        </Row>

      <CustomModal
        header={"Product Details"}
        isOpen={isOpenCatalogueItem}
        className=""
        toggle={() => setIsOpenCatalogueItem(!isOpenCatalogueItem)}
        size={"lg"}
        footer={
          <>
            <Button
              color={"link"}
              onClick={() => setIsOpenCatalogueItem(!isOpenCatalogueItem)}
            >
              Cancel
            </Button>
          </>
        }
      >
        <Label> {catlogProductDetails}</Label>
      </CustomModal>

      <CustomModal
        header={"Create Ticket"}
        isOpen={isTicketListOpen}
        toggle={() => setIsTicketListOpen(!isTicketListOpen)}
        size={"lg"}
        className="bgColor"
      >
        <ProductsAlertTicketCreate
          asinForTicketList={asinForTicketList}
          handleCreateAndViewTicketClose={handleCreateAndViewTicketClose}
          childProductData={childProductData}
        />
      </CustomModal>

      <CustomModal
        header={"1-Click Campaign"}
        isOpen={isCampaignOpen}
        toggle={() => setIsCampaignOpen(!isCampaignOpen)}
        size={"sm"}
        className="bgColor"
      >
        <QuickLaunchCampaignModal
          isCampaignOpen={isCampaignOpen}
          setIsCampaignOpen={setIsCampaignOpen}
          newCampaignDetails={newCampaignDetails}
          setNewCampaignDetails={setNewCampaignDetails}
          isManagementOpen={isManagementOpen}
          setIsManagementOpen={setIsManagementOpen}
          handleQuickLaunchCampaign={handleQuickLaunchCampaign}
          integerId={props.storeIntegerID}
          token={props.token}
        />
      </CustomModal>
      <CustomModal
        header={"1"}
        isOpen={isCampaignTutorialOpen}
        toggle={() => setIsCampaignTutorialOpen(!isCampaignTutorialOpen)}
        size={"sm"}
        className=""
      >
        <CampaignLaunchTutorialModal
          isOpen={isCampaignTutorialOpen}
          setIsOpen={setIsCampaignTutorialOpen}
          hideTutorial={hideCampaignTutorial}
          setHideTutorial={setHideCampaignTutorial}
        />
      </CustomModal>

    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    storeOverview: state.accountOverview.store.storeOverview,
    adProfiles: state.accountOverview.landing.adProfiles,
    weeklyReport: state.accountOverview.store.weeklyReport,
    ProductPerformanceReport: state.accountOverview.store.ProductPerformanceReport,
    TargetPerformanceReport: state.accountOverview.store.TargetPerformanceReport,
    StoreOverviewReport: state.accountOverview.store.StoreOverviewReport,
    WeeklySalesReport: state.accountOverview.store.WeeklySalesReport,
    SalesTrendsReport: state.accountOverview.store.SalesTrendsReport,
    WeeklySearchTermReport: state.accountOverview.store.WeeklySearchTermReport,
    CampaignPerformanceReport: state.accountOverview.store.CampaignPerformanceReport,

    storeLoading: state.accountOverview.store.loading,
    status: state.accountOverview.store.status,
    UserPermission: state.accountOverview.store.StoreUserPermission,
    error: state.loggedUser.error,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
    storeProducts: state.accountOverview.store.storeProducts,
    ProductTargetingReport: state.accountOverview.store.ProductTargetingReport,
    parentProducts: state.accountOverview.store.parentProducts,
    orphanProducts: state.accountOverview.store.orphanProducts,
    storeCampaigns: state.accountOverview.store.storeCampaigns,
    unclaimedCampaigns: state.accountOverview.store.unclaimedCampaigns,
    metricData: state.accountOverview.store.metricData,
    linkedToMRP: state.accountOverview.store.storeOverview.linkedToMRP,
  };
};
export default connect(mapStateToProps)(StoreOverviewNewProductPage);
