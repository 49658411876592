import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Container, Input, Row } from "reactstrap";
import GetWorkflowTaskService from "../../../../../../../Service/OPSServices/WorkflowsService/GetWorkflowTaskService";
import GetWorkflowMainCategoryService from "../../../../../../../Service/OPSServices/WorkflowsService/GetWorkflowMainCategoryService";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUploadAlt,
  faWindowClose,
  faTimes,
  faEye, faDownload, faCheck,faPaperclip
} from "@fortawesome/free-solid-svg-icons";
import GetAllWorkflowStatusService from "../../../../../../../Service/OPSServices/WorkflowsService/GetAllWorkflowStatusService";
import GetTaskHistoryDataService from "../../../../../../../Service/OPSServices/WorkflowsService/GetTaskHistoryDataService";
import CustomModal from "../../../../../../Components/Modals/CustomModal";
import ReactTable from "react-table";
import SaveTaskDetailsService from "../../../../../../../Service/OPSServices/WorkflowsService/SaveTaskDetailsService";
import LoadingOverlay from "react-loading-overlay";
import Loader from "react-loaders";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Editor ,SetInnerHtmlWithClassReplacement} from '../../../../../../../Utils/Constants';

function TaskDetailsComponents(props) {
  const [uploadFileOpen, setUploadFileOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [isHistoryPopup, setIsHistoryPopup] = useState(false);
  const [historyListDetails, setHistoryListDetails] = useState([]);
  const [filePath, setFilePath] = useState();
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false)
  const [searchText, setSearchText] = useState("");
  const [openCommentsPopup,setOpenCommentsPopup]=useState(false) 
  const [fullComment,setFullComment]=useState('')

  // -----
  const [flag, setFlag] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loading1, setLoading1] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)
  const [comment, setComment] = useState(props.taskDetails.answerObject[0] && props.taskDetails.answerObject[0].memo?props.taskDetails.answerObject[0].memo:'')
  const [prevComment, setPrevComment] = useState(props.taskDetails.answerObject[0] && props.taskDetails.answerObject[0].memo)
  const [status, setStatus] = useState('')
  const [member, setMember] = useState()
  const [taskData, setTaskData] = useState(false)
  // ----------

  useEffect(() => {
    setTaskData(props.taskDetails)
    fetchTaskHistory()
  }, [props.taskDetails]);
  const handleDragEnter = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    setFlag(1)
    const files = Array.from(e.dataTransfer.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };
  const handleFileChange = (e) => {
    setFlag(1)
    const files = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
  };
  const handleRemoveFile = (file) => {
    const updatedFiles = selectedFiles.filter((f) => f !== file);
    setSelectedFiles(updatedFiles);
  };
  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };
  const fetchTaskHistory = () => {
    setHistoryListDetails([])
    let data = {
      taskId: props.taskDetails.id,
      integerId: props.selectedStoreData.integerId,
      workflowMasterId: props.selectedWorkflowMasterId,
      workflowPhaseId: props.currentPhaseId
      // storeTaskId:56
    }

    GetTaskHistoryDataService(props.token, data).then((res) => {
      if (res) {
        console.log(res)
        setHistoryListDetails(res.historyList)
      }
    })
    // setIsHistoryPopup(true)
  };
  const handleQuillChange = (value) => {
    setComment(value)
    setFlag(1);
  };
  const handleTextChange = (e) => {
    setComment(e.target.value)
    setFlag(1);
  };
  const handleStatus = (e) => {
    setFlag(1);
    setStatus(e.target.value)
  };
  const handleAssigned = (e) => {
    setFlag(1);
    setMember(e.target.value)
  }
  const saveTaskDetails = () => {
    setLoadingButton(true)
    setUploadFileOpen(false)
    if (flag === 0) {
      return true;
    }
    let data;

    setLoading(true)
    const formData = new FormData();
    if (selectedFiles.length > 0) {

      formData.append("taskId", props.taskDetails.id);
      formData.append("taskText", (comment != prevComment) ? comment : 'Task has been updated');
      formData.append("workflowMasterId", props.selectedWorkflowMasterId);
      // formData.append("workflowPhaseId", currentPhaseId);
      formData.append("workflowPhaseId", props.currentPhaseId);
      formData.append("integerId", props.selectedStoreData.integerId);
      formData.append("assignedTo", member ? member : props.taskDetails.answerObject[0] ? props.taskDetails.answerObject[0].executor : 0);

      formData.append("status", status ? status : props.taskDetails.answerObject[0] ? props.taskDetails.answerObject[0].status : 0);
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("attachmentFile", selectedFiles[i]);
      }
      // return true
      SaveTaskDetailsService(props.token, formData).then((res) => {
        if (res.status) {
          console.log(res)
          setLoading(false)
          setComment('')
          setStatus('')
          setFlag(0)
          setSelectedFiles([])
          props.setAllTasks(res.tasklist.tasklist)
          props.setTaskList(res.tasklist.tasklist)
          props.setWorkflowPhaseCount(res.progressBarData.workflowPhasesCount);
          props.setTotalWorkflowPhaseCount(res.progressBarData.totalWorkflowPhasesCount);
          setLoadingButton(false)
          props.handleBackTaskList()
        }
      })
    }
    else {
      data = {
        taskId: props.taskDetails.id,
        taskText: (comment != prevComment) ? comment : 'Task has been updated',
        workflowMasterId: props.selectedWorkflowMasterId,
        assignedTo: member ? member : props.taskDetails.answerObject[0] ? props.taskDetails.answerObject[0].executor : 0,
        workflowPhaseId: props.currentPhaseId,
        integerId: props.selectedStoreData.integerId,
        status: status ? status : props.taskDetails.answerObject[0] ? props.taskDetails.answerObject[0].status : 0,
        attachmentFile: []
      }
      // console.log(comment)
      // console.log(prevComment)
      // console.log(data)
      // setLoadingButton(false)
      // return true
      SaveTaskDetailsService(props.token, data).then((res) => {
        if (res.status) {
          console.log(res)
          setLoading(false)
          setStatus('')
          setFlag(0)
          setComment('')
          setSelectedFiles([])
          props.setAllTasks(res.tasklist.tasklist)
          props.setTaskList(res.tasklist.tasklist)
          props.setWorkflowPhaseCount(res.progressBarData.workflowPhasesCount);
          props.setTotalWorkflowPhaseCount(res.progressBarData.totalWorkflowPhasesCount);
          setLoadingButton(false)
          props.handleBackTaskList()
        }
      })
    }


  }
  const fileExtensions = ['csv', 'docx', 'xlsx', 'xls','doc'];
  const stripHtmlTags = (html) => {
    if (!html) return '';
    return html.replace(/<[^>]*>/g, '');
  };
  const columns = [
    {
      Header: "Date",
      accessor: "createdAt_formated",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p>{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Comments",
      accessor: "memo",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
           <p
           className="m-0 comment-paragraph"
           style={{ color: "#3D85C6", cursor: "pointer" }}
           onClick={() =>
             viewIssueDescription(
               cellInfo.value,
             )
           }>{stripHtmlTags(cellInfo.value)}</p>
             {cellInfo.original.files.length > 0?<p className="mt-1" style={{fontSize:'15px'}}> 
             <FontAwesomeIcon
                className="mr-2"
                icon={faPaperclip}
                onClick={() =>  viewIssueDescription(cellInfo.value)}
            /></p>:''}
        </div>
      ),
    },
    {
      Header: "Assigned to",
      accessor: "executorName",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p>{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "User",
      accessor: "createdByUserName",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p>{cellInfo.value}</p>
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (cellInfo) => (
        <div className="d-flex justify-content-center w-100">
          <p>{cellInfo.value}</p>
        </div>
      ),
    },
  ]
  const viewIssueDescription=(value)=>{
    setOpenCommentsPopup(true)
    setFullComment(value)
  }
  const gridComments = SetInnerHtmlWithClassReplacement(fullComment);
  const filteredTasktHistory = historyListDetails.filter((row) => {
    const historyComment = row.memo.toLowerCase();
    const tasktHistoryCreateBy = (row.createdByUserName || '').toLowerCase();
    const taskHistoryStatus = (row.status || '').toLowerCase();
    const taskAssinged = (row.executorName || '').toLowerCase();
    return historyComment.includes(searchText.toLowerCase()) || tasktHistoryCreateBy.includes(searchText.toLowerCase()) || taskHistoryStatus.includes(searchText.toLowerCase()) || taskAssinged.includes(searchText.toLowerCase());
  });
  const downloadFile = async (file) => {
    console.log(file)
    if (true) {
      try {
        let fileName = file.filePath.split('images/')[1]
        fileName = fileName.split('.')[0]
        // const response = await fetch(file.filePath);
        const response = await fetch(file.filePath, {
          method: 'GET',
          //mode: 'no-cors', 
          referrerPolicy: 'no-referrer', // Set referrerPolicy here
          cache: 'no-store',
             });
        if (!response.ok) throw new Error(`Network response was not ok for ${file.id}`);

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const downloadLink = document.createElement('a');
        downloadLink.href = blobUrl;
        downloadLink.download = fileName || 'downloaded_file';
        // downloadLink.download = 'downloaded_file';

        document.body.appendChild(downloadLink);
        downloadLink.click();

        document.body.removeChild(downloadLink);
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error('Failed to download files:', error);
      }
    }
  }
  const show = (path) => {
    const separatedPath = path.split("images/");
    console.log("first");
    console.log(separatedPath[1]);
    const ext = separatedPath[1].split(".")[1];
    console.log(ext);
    if (ext == "xlsx" || ext == "csv" || ext == "xls" || ext == "docx"|| ext == "doc") {
      setFilePath(path);
      setIsAttachmentOpen(true);
      setTimeout(() => {
        setIsAttachmentOpen(false);
      }, 200);
    } else {
      setFilePath(path);
      setIsAttachmentOpen(true);
    }
  };
  const handletaskData = (task) => {
    fetchTaskHistory()
    console.log(task)
    setLoading1(true)
    setTaskData(task)
    setComment(task.answerObject[0] && task.answerObject[0].memo ? task.answerObject[0].memo : '')
    setPrevComment(task.answerObject[0] && task.answerObject[0].memo ? task.answerObject[0].memo : '')
    setSelectedFiles([])
    setUploadFileOpen(false)
    setTimeout(() => {
      setLoading1(false)
    }, 300)
  }
  return (
    <Fragment>
      <Row>
        <Col md={12}>
          <Card className=" p-4 card-of-problemarea">
            <Row>
              <Col md={5}>
                <ul>
                  {props.taskList &&
                    props.taskList.map((task) => (
                      <>
                        {props.currentPhaseId == task.WorkflowPhases && (
                          <li
                            style={{ cursor: "pointer" }}
                            className="mb-2"
                            key={task.id}
                            onClick={() => {
                              props.setTaskDetails(task);
                              handletaskData(task)
                            }
                            }
                          >
                            {props.taskDetails.taskText == task.taskText ?

                              <a href="#"><b><u>{task.taskText}</u></b></a>
                              : task.taskText
                            }
                          </li>
                        )}
                      </>
                    ))
                  }
                </ul>
              </Col>
              <Col md={7}>
                <LoadingOverlay
                  active={loading1}
                  styles={{
                    overlay: (base) => ({
                      ...base,
                      background: '#fff',
                      opacity: 1,
                    }),
                    content: (base) => ({
                      ...base,
                      color: '#000',
                    }),
                  }}
                  spinner={<Loader active type="ball-pulse" />}
                  text="Loading ..."
                >
                  {
                    props.taskDetails.answerObject[0] && props.taskDetails.answerObject[0].status == 6 ?
                      <Card className="p-4 card-of-problemarea">
                        <Row>
                          <Col md={9} className='pl-0'>
                            <p className="ml-3" style={{ fontSize: '16px' }}>
                              <b>{props.taskDetails.taskText}</b>
                            </p>
                          </Col>
                          <Col md={3}>
                            <h6 className="d-flex justify-content-end">
                              {/* <Button
                                onClick={() => fetchTaskHistory()}
                                className="btn-background-color"
                                title="History"
                              >History</Button>&nbsp; */}

                              <Button
                                onClick={() => { props.handleBackTaskList(); setUploadFileOpen(false) }}
                                className="info-button-background-none"
                                title="Close"
                              >
                                <FontAwesomeIcon style={{ color: "#000", }}
                                  icon={faTimes}
                                />
                              </Button>
                            </h6>

                          </Col>
                          {props.taskDetails.helpText &&
                            <Col md={12} > 
                            <p className="help-text-workflows mb-0 p-1"><b>Note :-</b> &nbsp;<p className="issue-discription-set mb-0" dangerouslySetInnerHTML={{ __html: props.taskDetails.helpText }}/></p>
                          </Col>
                          }
                        </Row>
                        <Row className='mt-2'>
                          <Col md={4}>
                            <h6>
                              <b>Status : </b>
                            </h6>
                            <p className="signedoff-dropdown "> Signed Off &nbsp;<FontAwesomeIcon style={{ color: '#fff', float: 'right', marginTop: '2px' }} icon={faCheck} />  </p>
                          </Col>
                          <Col md={4}>
                            <h6 >
                              <b>Assigned To : </b>
                            </h6>
                            <p>{props.taskDetails.answerObject[0].executorName}</p>
                          </Col>
                          <Col md={4}>
                            <h6 >
                              <b>Last Updated : </b>
                            </h6>
                            <p>{props.taskDetails.lastModified}</p>
                          </Col>
                          <Col md={12}>
                            <h6 >
                              <b>Last Updated By : </b> <span>{props.taskDetails.answerObject[0].updatedByName && props.taskDetails.answerObject[0].updatedByName.split(' ')[0] + ' ' + props.taskDetails.answerObject[0].updatedByName.split(' ')[1][0] + '.'}</span>
                            </h6>
                          </Col>
                        </Row>
                        <Row className='p-3 mt-4'>
                          <Col md={12}>
                            <h5><b>EXECUTION HISTORY </b></h5>
                          </Col>
                          <Col md={12}>
                            <Input
                              type='text'
                              className='w-50'
                              placeholder='Search history'
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                          </Col>
                          <Col md={12}>
                            <ReactTable
                              data={filteredTasktHistory}
                              columns={columns}
                              className="-fixed -highlight -striped mt-2"
                              getTheadProps={() => {
                                return {
                                  style: {
                                    overflowY: "hidden",
                                    background: "#B1FA63",
                                    color: "#243837",
                                  },
                                };
                              }}
                              defaultPageSize={5}
                              SubComponent={(row) => (
                                <div className="p-1">
                                  {console.log(row.original.files)}
                                  {row.original.files.length > 0 ? (
                                    <ReactTable
                                      data={row.original.files}
                                      columns={[
                                        {
                                          Header: "Name",
                                          accessor: "actualFileName",
                                          Cell: (cellInfo) => (
                                            <div className="d-flex justify-content-center w-100">
                                              {cellInfo.value}
                                            </div>
                                          ),
                                        },
                                        {
                                          Header: "Action",
                                          accessor: "filePath",
                                          Cell: (cellInfo) => (
                                            <div className="d-flex justify-content-center w-100">
                                              {
                                                fileExtensions.includes(cellInfo.value.split('images/')[1].split('.')[1]) ? (
                                                  <FontAwesomeIcon
                                                    className="ml-3"
                                                    style={{ cursor: 'pointer' }}
                                                    icon={faDownload}
                                                    onClick={() => downloadFile(cellInfo.original)}
                                                  />
                                                ) : (
                                                  <>
                                                    <FontAwesomeIcon
                                                      className="ml-3"
                                                      style={{ cursor: 'pointer' }}
                                                      icon={faEye}
                                                      onClick={() => show(cellInfo.value)}
                                                    />
                                                    <FontAwesomeIcon
                                                      className="ml-3"
                                                      style={{ cursor: 'pointer' }}
                                                      icon={faDownload}
                                                      onClick={() => downloadFile(cellInfo.original)}
                                                    />
                                                  </>
                                                )
                                              }
                                            </div>
                                          ),
                                        },
                                      ]}
                                      getTheadProps={() => {
                                        return {
                                          style: {
                                            overflowY: "hidden",
                                            background: "#B1FA63",
                                            color: "#243837",
                                          },
                                        };
                                      }}
                                      className="-striped -highlight"
                                      defaultPageSize={row.original.files.length}
                                      noDataText="No Attachments available"
                                    />
                                  ) : (
                                    <p className="text center">No Attachments.</p>
                                  )}
                                </div>
                              )}
                              noDataText={"No Data Found."}
                            />
                          </Col>
                        </Row>
                      </Card> :
                      <Card className="p-4 card-of-problemarea">
                        <Row>
                          <Col md={9} className='pl-0'>
                            <p className="ml-3 mb-0" style={{ fontSize: '16px' }}>
                              <b>{props.taskDetails.taskText}</b>
                            </p>
                          </Col>
                          <Col md={3}>
                            <h6 className="d-flex justify-content-end">
                              {/* <Button
                                onClick={() => fetchTaskHistory()}
                                className="btn-background-color"
                                title="History"
                              >History</Button>&nbsp; */}
                              <Button
                                onClick={() => { props.handleBackTaskList(); setUploadFileOpen(false) }}
                                className="info-button-background-none"
                                title="Close"
                              >
                                <FontAwesomeIcon style={{ color: "#000", }}
                                  icon={faTimes}
                                />
                              </Button>
                            </h6>
                          </Col>
                          {props.taskDetails.helpText &&
                            <Col md={12} > <p className="help-text-workflows mb-0 "><b>Note :-</b> &nbsp;<p className="issue-discription-set mb-0" dangerouslySetInnerHTML={{ __html: props.taskDetails.helpText }}/></p>
                          </Col>
                          }
                        
                        </Row>
                        <Row className='mt-2'>
                          <Col md={4}>
                            <h6>
                              <b>Status : </b>
                            </h6>
                            {
                              props.taskDetails.answerObject[0] && props.taskDetails.answerObject[0].status == 6 ?
                                <p className="signedoff-dropdown "> Signed Off &nbsp;<FontAwesomeIcon style={{ color: '#fff', float: 'right', marginTop: '2px' }} icon={faCheck} />  </p>
                                :
                                <select
                                  className={` ${props.taskDetails.answerObject[0] ?
                                    props.taskDetails.answerObject[0].status == 3 ? 'inprogress-dropdown form-control' :
                                      props.taskDetails.answerObject[0].status == 5 ? 'completed-dropdown form-control' :
                                        props.taskDetails.answerObject[0].status == 2 ? 'notstarted-dropdown form-control' :
                                          props.taskDetails.answerObject[0].status == 1 ? 'notapplicable-dropdown form-control' :
                                            props.taskDetails.answerObject[0].status == 4 ? 'postpond-dropdown form-control' :
                                              'form-control' : 'form-control'}`}
                                  onChange={(e) => handleStatus(e)}
                                  name="status"
                                  id="status"
                                >
                                  <option style={{ background: '#fff' }} value={0}>-- Select Status --</option>
                                  {props.statusList &&
                                    props.statusList.map((status) => (
                                      <option style={{ background: '#fff' }} key={status.id} value={status.id} selected={status.id === (props.taskDetails.answerObject[0] && props.taskDetails.answerObject[0].status) ? true : false}>
                                        {status.status}
                                      </option>
                                    ))}

                                </select>
                            }

                          </Col>
                          <Col md={4}>
                            <h6 >
                              <b>Assigned To : </b>
                            </h6>
                            <select
                              className="form-control"
                              name="assignedto"
                              id="assignedto"
                              onChange={(e) => handleAssigned(e)}
                            >
                              <option value={0}>-- Assigned to --</option>
                              {props.teamMembers &&
                                props.teamMembers.map((member) => (
                                  <option key={member.employeeId} value={member.employeeId} selected={member.employeeId === (props.taskDetails.answerObject[0] && props.taskDetails.answerObject[0].executor) ? true : false}>
                                    {member.employeeName}
                                  </option>
                                ))}
                            </select>
                          </Col>
                          <Col md={4}>
                            <h6 >
                              <b>Last Updated : </b>
                            </h6>
                            <p>{props.taskDetails.lastModified}</p>
                          </Col>
                          {
                            props.taskDetails.answerObject[0] && props.taskDetails.answerObject[0].status == 6 &&
                            <Col md={12}>
                              <h6 >
                                <b>Last Updated By : </b> <span>{props.taskDetails.answerObject[0].updatedByName && props.taskDetails.answerObject[0].updatedByName.split(' ')[0] + ' ' + props.taskDetails.answerObject[0].updatedByName.split(' ')[1][0] + '.'}</span>
                              </h6>
                            </Col>
                          }
                        </Row>
                        <Row className="mt-3">
                          <Col md={12}>
                            {/* <Input
                              id="Comment"
                              placeholder="Add Your Comments Here."
                              value={comment}
                              rows={3}
                              type="textarea"
                              name="ticketHistoryComment"
                              onChange={(e) => handleTextChange(e)}
                            /> */}
                            <ReactQuill
                              theme={'snow'}
                              modules={Editor.modules}
                              formats={Editor.formats}
                              value={comment}
                              onChange={handleQuillChange}
                              bounds={'.quill-prod'}
                              placeholder="Add Your Comments Here."/>
                          </Col>
                          <Col md={12} className='d-flex justify-content-center mt-3'>
                            <h6
                              onClick={() => setUploadFileOpen(!uploadFileOpen)}
                              style={{ cursor: 'pointer', textdecoration: 'underline' }}
                            >
                              <a href="#"> <b><u> Upload Files</u></b></a>
                              <FontAwesomeIcon
                                icon={faCloudUploadAlt}
                                style={{ fontSize: "20px", marginLeft: "10px" }}
                              />
                            </h6>
                          </Col>
                          <Col md={12} className='mt-3'>
                            {uploadFileOpen ? (
                              <div
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                                onDragEnter={handleDragEnter}
                                onDragLeave={handleDragLeave}
                              >
                                <label
                                  htmlFor="fileInput"
                                  className={`custom-brand-file-input text-center uploadfile ${dragging ? "dragging" : ""
                                    }`}
                                >
                                  <h5>
                                    <FontAwesomeIcon
                                      icon={faCloudUploadAlt}
                                      style={{ fontSize: "30px" }}
                                    />
                                  </h5>
                                  <h5>Drag &amp; Drop Files </h5>
                                  <h5>OR</h5>
                                  <input
                                    type="file"
                                    id="fileInput"
                                    name="ticketAttachment"
                                    className="custom-brand-file"
                                    onChange={handleFileChange}
                                    style={{ display: "none" }}
                                  />
                                  <Button
                                    onClick={() =>
                                      document.getElementById("fileInput").click()
                                    }
                                    style={{ background: "#B2A1FF" }}
                                  >
                                    Browse Files
                                  </Button>
                                </label>
                                <p style={{ fontSize: "13px" }}>Attached File List</p>
                                <ul style={{ color: "green", fontSize: "13px" }}>
                                  {selectedFiles.length > 0 ? (
                                    selectedFiles.map((file, index) => (
                                      <li key={index}>
                                        {file.name}
                                        <button
                                          className="btn"
                                          onClick={() => handleRemoveFile(file)}
                                        >
                                          <FontAwesomeIcon
                                            color="red"
                                            size="15px"
                                            icon={faWindowClose}
                                          />
                                        </button>
                                      </li>
                                    ))
                                  ) : (
                                    <li>No files attached</li>
                                  )}
                                </ul>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="text-center">
                              <Button
                                variant="primary"
                                className="btn-background-color "
                                onClick={() => { saveTaskDetails() }}
                                disabled={flag != 0 ? loadingButton ? true : false : true}
                              >
                                {loadingButton ? 'Submitting...' : 'Submit'}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <Row className='p-3 mt-4'>
                          <Col md={12}>
                            <h5><b>EXECUTION HISTORY </b></h5>
                          </Col>
                          <Col md={12}>
                            <Input
                              type='text'
                              className='w-50'
                              placeholder='Search history'
                              onChange={(e) => setSearchText(e.target.value)}
                            />
                          </Col>
                          <Col md={12}>
                            <ReactTable
                              data={filteredTasktHistory}
                              columns={columns}
                              className="-fixed -highlight -striped mt-2"
                              getTheadProps={() => {
                                return {
                                  style: {
                                    overflowY: "hidden",
                                    background: "#B1FA63",
                                    color: "#243837",
                                  },
                                };
                              }}
                              defaultPageSize={5}
                              SubComponent={(row) => (
                                <div className="p-1">
                                  {console.log(row.original.files)}
                                  {row.original.files.length > 0 ? (
                                    <ReactTable
                                      data={row.original.files}
                                      columns={[
                                        {
                                          Header: "Name",
                                          accessor: "actualFileName",
                                          Cell: (cellInfo) => (
                                            <div className="d-flex justify-content-center w-100">
                                              {cellInfo.value}
                                            </div>
                                          ),
                                        },
                                        {
                                          Header: "Action",
                                          accessor: "filePath",
                                          Cell: (cellInfo) => (
                                            <div className="d-flex justify-content-center w-100">
                                              {
                                                fileExtensions.includes(cellInfo.value.split('images/')[1].split('.')[1]) ? (
                                                  <FontAwesomeIcon
                                                    className="ml-3"
                                                    style={{ cursor: 'pointer' }}
                                                    icon={faDownload}
                                                    onClick={() => downloadFile(cellInfo.original)}
                                                  />
                                                ) : (
                                                  <>
                                                    <FontAwesomeIcon
                                                      className="ml-3"
                                                      style={{ cursor: 'pointer' }}
                                                      icon={faEye}
                                                      onClick={() => show(cellInfo.value)}
                                                    />
                                                    <FontAwesomeIcon
                                                      className="ml-3"
                                                      style={{ cursor: 'pointer' }}
                                                      icon={faDownload}
                                                      onClick={() => downloadFile(cellInfo.original)}
                                                    />
                                                  </>
                                                )
                                              }
                                            </div>
                                          ),
                                        },
                                      ]}
                                      getTheadProps={() => {
                                        return {
                                          style: {
                                            overflowY: "hidden",
                                            background: "#B1FA63",
                                            color: "#243837",
                                          },
                                        };
                                      }}
                                      className="-striped -highlight"
                                      defaultPageSize={row.original.files.length}
                                      noDataText="No Attachments available"
                                    />
                                  ) : (
                                    <p className="text center">No Attachments.</p>
                                  )}
                                </div>
                              )}
                              noDataText={"No Data Found."}
                            />
                          </Col>
                        </Row>
                        {/* </Card> */}
                      </Card>
                  }
                </LoadingOverlay>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <CustomModal
        // header={"Attachment"}
        header={<> Attachment
          <FontAwesomeIcon className="ml-3" style={{ cursor: 'pointer' }} icon={faDownload} onClick={() => downloadFile({ 'filePath': filePath, 'id': 0 })} />
        </>}
        isOpen={isAttachmentOpen}
        toggle={() => setIsAttachmentOpen(!isAttachmentOpen)}
        size={"lg"}
        className='curve-popup'
      >
        <div>
          <iframe
            src={
              filePath ? filePath : ''
            }
            title="File Display"
            width="100%"
            height="500"
          ></iframe>
        </div>
      </CustomModal>
      <CustomModal
        header={"Comments"}
        isOpen={openCommentsPopup}
        toggle={() => setOpenCommentsPopup(!openCommentsPopup)}
        size={"lg"}
        className='curve-popup'
      >
        <div>
         {/* <p className="issue-discription-set" dangerouslySetInnerHTML={{__html : setInnerHtmlWithClassReplacement }}/> */}
         <p className="issue-discription-set" dangerouslySetInnerHTML={{ __html: gridComments }}/>
        </div>
      </CustomModal>
    </Fragment>

  );
}
const mapStateToProps = (state) => {
  return {
    token: state.loggedUser.token,
    accessLevel: state.loggedUser.accessLevel,
    status: state.loggedUser.status,
    error: state.loggedUser.error,
    storeOverview: state.accountOverview.store.storeOverview,
    storeProducts: state.accountOverview.store.storeProducts,
    newUser: state.loggedUser.newUser,
    needsAdvertisingAPIAuth: state.loggedUser.needsAdvertisingAPIAuth,
    user: state.LWA.user,
    isAuth: state.LWA.isAuth,
    enableMobileMenu: state.theme.enableMobileMenu,
    enableClosedSidebar: state.theme.enableClosedSidebar,
    adProfiles: state.accountOverview.landing.adProfiles,
  };
};

export default connect(mapStateToProps)(TaskDetailsComponents);
